import React, {useContext, useEffect, useState} from "react";
import CardSwap from "../../components/CardSwap";
import styled from "styled-components";
import useFetch from "../../hooks/useFetch";
import {Apis} from "../../api/Apis";
import {InfoContext} from "../../config/InfoContext";
import Loading from "react-loading"
import {showAlert} from "./CouponList";
import {ApiServicev2} from "../../api/Apiv2";

const ContainerCards = styled.div`
  display: flex;
  justify-content: flex-start;
  overflow-y:auto;
  gap: 1rem;
`;

const SwapList = () => {
  const {sala, document,card} = useContext(InfoContext);
  const [dataPoints, setDataPoints] = useState(null)
  const {data, loading} = useFetch(Apis.PC + `/catalog-products/active-inactive?salaId=${sala.id}&enabled=true`, []);

  const getCouponData = () => {
    const params = {document: document,card: card, salaId: sala.id};
    ApiServicev2.couponSummary(params)
      .then((res) => {
        setDataPoints(res.data);
      })
      .catch((error) => {
        setDataPoints(null);
        showAlert(error);
      });
  };

  useEffect(() => {
    getCouponData();
  }, []);

  return (
    dataPoints && (
      <>
        {!loading ? (
          <ContainerCards>
            {data.length > 0 ? (
              data
              .filter((element) => element.id !== 772) //TODO fijo solo para huaral
              .map((element) => (
                <CardSwap
                  key={element.id}
                  title={element.name}
                  couponTypeId={element.couponTypeId}
                  points={element.point}
                  id={element.id}
                  url={`${process.env.REACT_APP_S3_WARGOS_ASSETS}${element.photoUrl}`}
                  dataPoints={dataPoints}
                />
              ))
            ) : (
              <div style={{ display: "flex",width: "100%",justifyContent: "center"}}>
                <span>No hay artículos disponibles</span>
              </div>
            )}
          </ContainerCards>
        ) : (
          <div style={{display: "flex", justifyContent: "center"}}>
            <Loading />
          </div>
        )}
      </>
    )
  );
};

export default SwapList;
