import React, {useContext, useEffect, useRef, useState} from 'react';
import '../styles/Login.css';
import Button from "../components/Button";
import BlackCard from "../components/BlackCard";
import NumberPad from "../components/NumberPad";
import image from '../assets/img/cuponticket.png';
// include styles
import 'rodal/lib/rodal.css';
import {ApiServicev2} from "../api/Apiv2";
import withReactContent from "sweetalert2-react-content";
import Swal from 'sweetalert2'
import {InfoContext} from "../config/InfoContext";
import {useHistory} from "react-router-dom";
import styled from "styled-components"
import {getDocumentType} from "../components/services";
import dreamsHomeBg from "../assets/img/Fondo-AppDreams-1.jpg"
import defaultBg from "../assets/img/bg-raffle-2.jpg"

const MySwal = withReactContent(Swal);

const BackgroundLogin = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${props => props.img});
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  display: flex;
  background-attachment: fixed;
`;

const ClientLogin = (props) => {

  let documentInput;

  const [document, setDocument] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const [numpad] = useState(false);

  const {sala, printer, setData, setDocument: setDocumentCtx, setCard: setCardCtx} = useContext(InfoContext);
  let history = useHistory();

  const [inputType, setInputType] = useState('card');

  const handleOptionChange = (event) => {
    setInputType(event.target.value);
    setDocument('');
    setCardNumber('');
  };

  const globalConfig = getDocumentType()
  const showCardInput = globalConfig.showCardInput

  const login = () => {
    let params = {salaId: sala.id}

    if (inputType === 'card' && showCardInput) {
      if (cardNumber === undefined || cardNumber === '') {
        console.log('empty card')
        return
      }
      if (cardNumber.length < 6) {
        showAlert('Ingrese un número de tarjeta valido')
        return
      }

      //remove symbols ? and , from cardNumber string
      const fixedCardNumber = cardNumber.replace(/[?;,]/g, '')

      params.card = fixedCardNumber
    } else {
      if (document === undefined || document === '') {
        console.log('empty document')
        return
      }
      if (document.length < 6) {
        showAlert('Ingrese un documento valido')
        return
      }

      params.document = document
    }

    setLoading(true);

    ApiServicev2.couponSummary(params).then(res => {
      setData(res.data);
      setDocumentCtx(document);
      setCardCtx(cardNumber)
      history.push('home/coupon');
    }).catch(error => {
      setLoading(false);
      setDocument('');
      setData(null)
      setDocumentCtx('');
      showAlert(error);
    });
  };

  const updateDocument = (document) => {
    setDocument(document);
    documentInput.focus();
  };

  const showAlert = (message) => {
    return MySwal.fire({
      title: message,
      icon: 'error',
    })
  };

  let homeBackground = defaultBg
  console.log('host: ' + window.location.host)
  if (window.location.host === 'dreams.kiosko.wargos.cloud') {
    homeBackground = dreamsHomeBg
  }

  useEffect(() => {
    if (showCardInput) {
      inputRef.current.focus();
    }
  }, [inputType, showCardInput]);

  return (
    <BackgroundLogin img={homeBackground} className="d-flex main">
      <BlackCard>
        <form 
          onSubmit={login}
          style={{display: 'flex', flexDirection: 'column', gap: '16px', padding: '32px', width: '460px'}}
        >
          <div style={{display: "flex", justifyContent: "center"}}>
            <img src={image} alt='coupon' width="150px" height="150px" />
          </div>
          <span className="title" style={{userSelect: 'none'}}>
            {printer !== null ?
              'IMPRIMA SUS CUPONES AQUÍ'
              :
              'CONSULTE SUS CUPONES AQUÍ'
            }
          </span>
          {showCardInput ?
            <>
              <div className="toggle-button">
                <input
                  type="radio"
                  id="documentOption"
                  name="input-option"
                  value="document"
                  checked={inputType === 'document'}
                  onChange={handleOptionChange}
                />
                <label htmlFor="documentOption">DOCUMENTO</label>
                <input
                  type="radio"
                  id="cardOption"
                  name="input-option"
                  value="card"
                  checked={inputType === 'card'}
                  onChange={handleOptionChange}
                />
                <label htmlFor="cardOption">TARJETA</label>

              </div>
              <input
                ref={inputRef}
                className="input-dni"
                autoFocus
                type={inputType === 'card' ? 'number' : 'text'}
                placeholder={inputType === 'card' ? 'Número de tarjeta' : 'Número de ' + globalConfig.documentType}
                value={inputType === 'card' ? cardNumber : document}
                onChange={inputType === 'card' ?
                  e => setCardNumber(e.target.value)
                  :
                  e => setDocument(e.target.value)
                }
              />
            </>
            :
            <input
              className="input-dni"
              type="text"
              autoFocus
              placeholder={"Número de " + globalConfig.documentType}
              value={document}
              onChange={e => setDocument(e.target.value)}
            />
          }

          {numpad &&
            <NumberPad value={document} onChange={updateDocument} />
          }

          <Button type='submit' onClick={() => login()} loading={loading} title={'Acceder'} />
        </form>
      </BlackCard>
    </BackgroundLogin>

  );
};

export default ClientLogin;
